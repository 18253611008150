import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage as Img, getImage, withArtDirection } from 'gatsby-plugin-image'
import Slider from "react-slick"
import SportIcon from "../images/icons/sport.svg"
import { Link } from "../components/link"
import Breadcrumb from './elements/breadcrumb'
import withPreview from '../utility/with-preview'
import ChevronRightIcon from '../images/icons/material/chevron_right.svg'


const settings = (length = 5) => ({
  arrows: false,
  dots: false,
  infinite: false,
  autoplay: false,
  slidesToShow: Math.min(length, 5),
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: Math.min(length, 4),
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: Math.min(length, 2.4),
        slidesToScroll: 2
      }
    }
  ]
});

const Hero = ({ data: { imageDesktop, imageTablet, imageMobile, imageTitle }, page, menu }) => {
  const sources = useMemo(() => {
    return imageMobile || imageTablet ? withArtDirection(getImage(imageDesktop.node), []
    .concat(
      imageTablet ? {
        media: "(max-width: 992px)",
        image: getImage(imageTablet.node),
      }: []
    ).concat(
      imageMobile ? {
        media: "(max-width: 576px)",
        image: getImage(imageMobile.node),
      }: []
    )): getImage(imageDesktop.node);
  }, [imageMobile, imageTablet, imageDesktop]);

  const slides = menu ? menu.pageTitle.slice(0, 5) : [];
  
  return (
    <div className={`hero${menu ? ' hero--menu': ''} d-flex flex-column justify-content-center`}>
      <Img image={sources} alt={imageDesktop.node.description || ''} className="hero__img" />
      {page ? (
        <div className="hero__breadcrumb">
          <div className="container">
            <Breadcrumb page={page} />
          </div>
        </div>
      ): null}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="hero__content">
              <h1 className="hero__title text-white" dangerouslySetInnerHTML={{__html: imageTitle.value }}></h1>
              {imageTitle.content && imageTitle.content.imageSubtitle && imageTitle.content.imageSubtitle.value ? (
                <div className="hero__subtitle text-white" 
                  dangerouslySetInnerHTML={{__html: imageTitle.content.imageSubtitle.value }}></div>
              ): null}
              {imageTitle.content && imageTitle.content.btnText && imageTitle.content.btnText.value ? (
                <Link to={imageTitle.content.headerLink.value} 
                  className={`nj-btn ${imageTitle.content.btnText.content && imageTitle.content.btnText.content.variantWhite ? 'nj-btn--light':'nj-btn--primary'}`}>
                  {imageTitle.content.btnText.value}
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {menu ? (
        <div className="hero__menu w-100 mx-auto">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 pr-0 pr-lg-2">
                <Slider className="hero__menu-carousel" {...settings(slides.length)} >
                  {slides.map((item, i) => (
                    <div key={i}>
                      <div className={`hero__menuitem${i === 0 ? ` hero__menuitem--first` : ''}`}>
                        {item.content.icon.value === 'sport' ? <SportIcon className="hero__icon mb-1" /> : <i className={`nj-icon nj-icon-${item.content.icon.value} mb-1`}></i>}
                        <h4 className="hero__menutitle">
                          <Link to={item.content.link.value}>
                            {item.value}
                          </Link>
                        </h4>
                        <div className="hero__menutext d-none d-lg-block">{item.content.description.value}</div>
                        {item.content.ctaText && (
                          <div className="hero__menulink">
                            {item.content.ctaText.map((el, i)=> (
                              <div key={i}>
                                <a href={el.content.ctaLink.value} target="_blank" rel="noopener noreferrer">
                                  {el.value}
                                  <ChevronRightIcon className="material-svg" />
                                </a>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            </div>
        </div>
      ): null}
    </div>
  );
}

export default withPreview(Hero);
export const heroFragment = graphql`fragment SiteHeader_HeroFragment on LiferaySiteHeader_Hero{
  siteId
  articleId
  imageDesktop {
    node {
      description
      gatsbyImageData(width: 1440)
    }
  }
  imageTablet {
    node {
      gatsbyImageData(width: 992)
    }
  }
  imageMobile {
    node {
      gatsbyImageData(width: 576)
    }
  }
  imageTitle {
    value
    content {
      imageSubtitle {
        value
      }
      btnText {
        value
        content {
          variantWhite
        }
      }
      headerLink {
        value
      }
    }
  }
}
`