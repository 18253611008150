import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage as Img, getImage, withArtDirection } from 'gatsby-plugin-image'
import { Link } from "./link"
import Breadcrumb from "./elements/breadcrumb"
import Number1Icon from "../images/icons/number-1.svg"
import withPreview from '../utility/with-preview'


const HeroIcons = ({ data: { imageDesktop, imageTablet, imageMobile, imageTitle }, page }) => {
  const sources = useMemo(() => {
    return imageMobile || imageTablet ? withArtDirection(getImage(imageDesktop.node), []
    .concat(
      imageTablet ? {
        media: "(max-width: 1024px)",
        image: getImage(imageTablet.node),
      }: []
    ).concat(
      imageMobile ? {
        media: "(max-width: 576px)",
        image: getImage(imageMobile.node),
      }: []
    )): getImage(imageDesktop.node);
  }, [imageMobile, imageTablet, imageDesktop]);
  
  return (
    <div className="hero-icons">
      <div className="hero-icons__wrapper d-flex flex-column justify-content-center pt-lg-5 pb-6">
        <Img image={sources} alt={imageDesktop.node.description || ''} className="hero-icons__img" />
        {page ? (
          <div className="hero-icons__breadcrumb">
            <div className="container">
              <Breadcrumb page={page} />
            </div>
          </div>
        ): null}
        <div className="container">
          <div className="row">
            <div className={`col-12 ${imageTitle.icon && imageTitle.icon.length > 0 ? `pt-6` : 'pt-7 mt-3 mt-md-0'}`}>
              <h1 className="hero-icons__title mb-2" dangerouslySetInnerHTML={{ __html: imageTitle.value }}></h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5">
              {imageTitle.content && imageTitle.content.imageSubtitle ? (
                <div className="hero-icons__subtitle pb-4" dangerouslySetInnerHTML={{__html: imageTitle.content.imageSubtitle.value}}></div>
              ) : null}
              {imageTitle.content && imageTitle.content.headerLink && imageTitle.content.btnText && imageTitle.content.btnText.value ? (
                <div className="d-flex mt-4">
                  <Link to={imageTitle.content.headerLink.value} 
                    className={`nj-btn ${imageTitle.content.btnText.content && imageTitle.content.btnText.content.variantWhite ? 'nj-btn--light': 'nj-btn--primary'} nj-btn--lg ${imageTitle.icon && imageTitle.icon.length > 0 ? `mt-lg-7` : 'mt-0'}`}>
                    {imageTitle.content.btnText.value}
                  </Link>
                </div>
              ): null}
            </div>
            {imageTitle.content && imageTitle.content.icon ? (
              <div className="col-lg-6 offset-lg-1 d-flex flex-wrap justify-content-between justify-content-lg-start mt-4 mt-lg-0 pr-md-0">
                <div className="row w-100">
                  {imageTitle.content.icon.map((icon, i) => (
                    <div key={i} className={`hero-icons__icon mt-3 col-6 col-md-3 col-lg-4 px-lg-1${icon.content && icon.content.leftOffset ? ' offset-lg-4' : ''}`}>
                      {icon.content && icon.content.overTitle && icon.content.overTitle.value ? (
                        <div className="hero-icons__icon-title t-kilo font-weight-bold text-blue-corporate mb-2">
                          {icon.content.overTitle.value}
                          </div>
                      ) : <div className="hero-icons__icon-title-placeholder"></div>}
                      {icon.value === 'number-1' ? (
                        <Number1Icon/>
                      ) : icon.value && <i className={`nj-icon nj-icon-${icon.value} nj-icon--white size-72`}></i>}
                      {icon.content && icon.content.iconDescription ? (
                        <div className="mt-1" dangerouslySetInnerHTML={{__html: icon.content.iconDescription.value }}></div>
                      ) : null} 
                    </div>
                  ))}
                </div>
            </div>
            ): null }
          </div>
        </div>
      </div>
    </div>
  );
}

export default withPreview(HeroIcons);
export const heroIconsFragment = graphql`fragment SiteHeader_Hero_IconeFragment on LiferaySiteHeader_Hero_Icone{
  siteId
  articleId
  imageDesktop {
    node {
      description
      gatsbyImageData(width: 1440)
    }
  }
  imageTablet {
    node {
      gatsbyImageData(width: 1024)
    }
  }
  imageMobile {
    node {
      gatsbyImageData(width: 576)
    }
  }
  imageTitle {
    value
    content {
      imageSubtitle {
        value
      }
      btnText {
        value
        content {
          variantWhite
        }
      }
      headerLink {
        value
      }
      icon {
        value
        content {
          overTitle {
            value
          }
          leftOffset
          iconDescription {
            value
          }
        }
      }
    }
  }
}`;